<template>
  <div class="hero d-flex justify-center align-end">
    <div class="back"></div>
    <v-container>
      <div class="contenedor cards">
        <section v-if="this.$vuetify.breakpoint.smAndUp" align="center">
          <span class="titulo__scan"
            >Coloque el D.N.I en el scanner para su acreditacion</span
          >
          <br />
        </section>
        <v-row class="d-flex align-center flex-column">
          <v-col cols="4" class="pb-0">
            <v-text-field
              class="white-text"
              ref="miInput"
              v-model="valorCodigo"
              v-on:keyup.enter="actualizarLista()"
              autofocus
              type="password"
              label="Codigo de Barras"
              color="white"
              outlined
              append-icon="mdi-barcode-scan"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pt-0">
            <v-btn block @click="handleForm()" color="#7a5013" dark rounded
              >carga manual</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="dialog" width="700">
      <v-card color="blue-grey darken-3" dark>
        <v-img
          src="@/assets/img/fns.jpeg"
          :height="pantalla > 1000 ? 600 : 0"
          width="700"
          max-height="400"
        ></v-img>
        <v-card-title style="justify-content: center">
          <span class="titulo-card font-weight-bold" style="text-align: center"
            >Bienvenido/a</span
          >
        </v-card-title>

        <v-card-text
          v-if="respuesta"
          class="text-h2"
          style="text-align: center; color: white"
        >
          "{{ nombre }}"
        </v-card-text>

        <v-card-text
          v-else
          class="text-h2 font-weight-bold"
          style="text-align: center"
        >
          <span style="color: white; font-size: 40px; display: block"
            >Registro no encontrado</span
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <CargaManual :handleDialog="dialogForm" @cerrarForm="handleForm" />
  </div>
</template>

<script>
// import { json } from 'body-parser';
import axios from "axios";

import CargaManual from "../components/cargaManual.vue";

export default {
  components: {
    CargaManual,
  },
  data() {
    return {
      dialogForm: false,
      pantalla: "",

      dialog: false,

      valorCodigo: "",
      dniInput: "",
      datos: {},
      respuesta: "",
      dni: "",
      nombre: "",
      message: "",
      apellido: "",
    };
  },

  created() {
    //  this.$refs.miInput.$el.focus()
    this.pantalla = screen.height;
    console.log("pantalla", this.pantalla);
  },

  methods: {
    handleForm(item) {
      console.log("handleForm", item);
      if (item == false) {
        this.dialogForm = false;
      } else {
        this.dialogForm = true;
      }
    },
    async actualizarLista() {
      console.log("valorCodigo", this.valorCodigo);

      let datosPersonales = {
        nombre: "",
        dni: "",
      };

      try {
        // Verificar si el código contiene un patrón específico para el primer formato
        if (
          this.valorCodigo.startsWith("0") &&
          this.valorCodigo.split('"').length >= 8
        ) {
          // Procesar el primer tipo de código
          this.datos = this.valorCodigo.split('"');
          console.log("datos en el if", this.datos);

          if (this.datos.length >= 5) {
            // Asegura que hay suficientes partes en el array
            datosPersonales.dni = this.datos[4];
            datosPersonales.nombre = this.datos[1] + " " + this.datos[2];
          } else {
            throw new Error("Formato inválido para el primer tipo de código.");
          }
        } else if (this.valorCodigo.split('"').length >= 6) {
          // Procesar el segundo tipo de código
          this.datos = this.valorCodigo.split('"');
          console.log("datos en el else", this.datos);

          if (this.datos.length >= 6) {
            // Asegura que hay suficientes partes en el array
            datosPersonales.dni = this.datos[1].trim();
            datosPersonales.nombre = this.datos[4] + " " + this.datos[5];
          } else {
            throw new Error("Formato inválido para el segundo tipo de código.");
          }
        } else {
          throw new Error(
            "El código proporcionado no coincide con ningún formato válido."
          );
        }

        // Validar los datos extraídos
        const dniValido = /^\d{7,8}$/.test(datosPersonales.dni); // Verifica si el DNI es numérico y tiene entre 7 u 8 dígitos
        const nombreValido =
          datosPersonales.nombre &&
          datosPersonales.nombre.trim().length > 0 &&
          /^[a-zA-Z\s]+$/.test(datosPersonales.nombre); // Verifica que el nombre no esté vacío y contenga solo letras y espacios

        if (dniValido && nombreValido) {
          console.log("datosPersonales válidos", datosPersonales);
          // Aquí puedes proceder a enviar el objeto `datosPersonales`
          await axios
            .post(process.env.VUE_APP_BACKEND_ENVIRONMENT, {
              datos: datosPersonales,
            })
            .then((res) => {
              this.respuesta = res.data;
              console.log(this.respuesta);
              this.nombre = res.data.nombre;
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.error("Error: Datos personales inválidos", datosPersonales);
          // Manejo de error si los datos son inválidos
        }
      } catch (error) {
        console.error("Error al procesar el código:", error.message);
        // Mostrar un mensaje de error amigable al usuario o realizar otra acción de manejo de error
      }
      console.log("datosPersonales", datosPersonales);

      this.dialog = true;
      if (this.respuesta) {
        console.log("aac", this.respuesta);
        setTimeout(() => {
          this.dialog = false;
          (this.valorCodigo = ""), (this.respuesta = "");
        }, 4000);
      } else {
        setTimeout(() => {
          this.dialog = false;
          (this.valorCodigo = ""), (this.respuesta = "");
        }, 8000);
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@500&display=swap");
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0.5;
}

.titulo__scan {
  font-size: 3rem;
  color: white;
}
.texto__conect {
  font-size: 2rem;
  text-transform: uppercase;
  color: white;
  font-family: "Chakra Petch", sans-serif;
}
.contain__text {
  margin: auto;
  background-color: rgb(16 83 153);
  padding: 10px;
  border-radius: 20px;
}
.text-principal {
  text-transform: uppercase;
  font-size: 6rem;
  font-family: "Chakra Petch", sans-serif;
}
.divisor {
  border-left: 3px solid white;
  height: 100px;
  margin-right: 10px;
}
.img__escudo {
  width: 100px !important;
  height: 50px;
}

.hero {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/bgc.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uno {
  height: 50vh;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}
.dos {
  height: 50vh;
}
.img2 {
  width: 45%;
  height: 100%;
  object-fit: contain;
}
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.titulo-card {
  font-size: 3.5rem;
}
.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}
.input__manual {
  display: none;
}
@media screen and (max-width: 650px) {
  .cards {
    display: none;
  }
  .texto__conect {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: white;
    font-family: "Chakra Petch", sans-serif;
  }
  .textoPolicia {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 650px) {
  .input__manual {
    position: absolute;
    bottom: -110px;
    left: 0;
    right: 0;
    display: block;
    background: white;
    border-radius: 10px;
  }
  .titulo-card {
    font-size: 2.5rem;
  }
  .text-principal {
    text-transform: uppercase;
    font-size: 3rem;
    font-family: "Chakra Petch", sans-serif;
  }
}
.white-text .v-input__control .v-input__slot,
.white-text .v-input__control .v-label,
.white-text input {
  color: white !important;
  border-color: white !important;
}

.white-text .v-input__control .v-label {
  color: white !important; /* Para el label */
}

.white-text .v-input__slot::before,
.white-text .v-input__slot::after {
  border-color: white !important; /* Para el borde inferior */
}
</style>

<style>
section {
  position: relative;
}
</style>
