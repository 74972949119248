<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="handleDialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Complete los campos</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Apellido y Nombre"
                    v-model="nombre"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field label="DNI" v-model="dni"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="cerrarDialog()"> Cancelar </v-btn>
            <v-btn color="blue darken-1" text @click="envioForm()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "cargaManual",
  props: {
    handleDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nombre: "",
      dni: null,
    };
  },
  methods: {
    cerrarDialog() {
      this.$emit("cerrarForm", false);
    },
    async envioForm() {
      let datosPersonales = {
        nombre: this.nombre,
        dni: this.dni,
      };
      await axios
        .post(process.env.VUE_APP_BACKEND_ENVIRONMENT, {
          datos: datosPersonales,
        })
        .then((res) => {
          this.respuesta = res.data;
          console.log(this.respuesta);
          this.nombre = res.data.nombre;
        })
        .catch((e) => {
          console.log(e);
        });
      console.log(this.nombre, this.dni);
      this.$emit("cerrarForm", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
